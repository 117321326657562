<template>
  <interna>
    <breadcrumb
      :itens="[
        { legenda: 'Dashboard', route: { name: 'dashboard' } },
        { legenda: 'Inscrição' },
      ]"
    />
    <section id="inscricao" v-if="categoria && selo">
      <div class="container">
        <div class="row my-1 mt-5">
          <div class="col-1-1 padding-x1 col-md-2-4 offset-md-1-4 text-center">
            <h4>Faça a sua Inscrição</h4>
            <p class="fw-bold text-uppercase" :style="{ color: selo.Cor }">
              {{ selo.Nome }} - {{ categoria.Nome }}
            </p>
          </div>
        </div>

        <div class="row my-3">
          <div class="col-1-1 padding-x1 col-md-2-4 offset-md-1-4 text-center">
            <div class="alert alert-warning" role="alert">
              Os arquivos enviados devem ser Documentos em PDF e devem ter no
              máximo {{ arquivo.limite / 1024 }}kb de tamanho.
            </div>
          </div>
        </div>

        <form enctype="multipart/form-data" @submit.prevent="submit">
          <div class="row">
            <div class="col-1-1 padding-x1 col-md-2-4 offset-md-1-4">
              <div class="mb-3">
                <label for="formFile" class="form-label"
                  >Documento de CNPJ</label
                >
                <input
                  class="form-control"
                  type="file"
                  :accept="arquivo.tipos"
                  :max-file-size="arquivo.limite"
                  required
                  @change="mandou_arquivo($refs.cnpj, 'this.campos.cnpj')"
                  ref="cnpj"
                  :disabled="loading"
                />
              </div>

              <div class="mb-3">
                <label for="formFile" class="form-label">Atividade</label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  v-model="campos.segmento"
                  required
                  placeholder="Selecione uma atividade"
                >
                  <option
                    v-for="(segmento, segmento_i) in segmentos"
                    :key="segmento_i"
                    :value="segmento"
                  >
                    {{ segmento }}
                  </option>
                </select>
              </div>
              <!--
              <div class="mb-3">
                <label for="formFile" class="form-label"
                  >Licença Sanitária</label
                >
                <input
                  class="form-control"
                  type="file"
                  :accept="arquivo.tipos"
                  :max-file-size="arquivo.limite"
                  required
                  @change="
                    mandou_arquivo($refs.sanitaria, 'this.campos.sanitaria')
                  "
                  ref="sanitaria"
                  :disabled="loading"
                />
              </div>

              <div class="mb-3">
                <label for="formFile" class="form-label"
                  >Alvará de Funcionamento</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  :accept="arquivo.tipos"
                  :max-file-size="arquivo.limite"
                  required
                  @change="mandou_arquivo($refs.alvara, 'this.campos.alvara')"
                  ref="alvara"
                  :disabled="loading"
                />
              </div>

              <div class="mb-3">
                <label for="formFile" class="form-label"
                  >Atestado de Regularidade do Bombeiro</label
                >
                <input
                  class="form-control"
                  type="file"
                  id="formFile"
                  :accept="arquivo.tipos"
                  :max-file-size="arquivo.limite"
                  required
                  @change="
                    mandou_arquivo($refs.bombeiro, 'this.campos.bombeiro')
                  "
                  ref="bombeiro"
                  :disabled="loading"
                />
              </div>
            -->
              <div
                class="mb-3 termo"
                v-if="selo.Termos"
                v-html="get_termos()"
              ></div>
              <div class="form-check margin-y1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="aceito"
                  checked
                  v-model="campos.aceito"
                  required
                />
                <label class="form-check-label" for="aceito"
                  >Declaro que li os termos, entendi e concordo.</label
                >
              </div>
            </div>
          </div>

          <div class="row my-3">
            <div
              class="
                col-1-1
                padding-x1
                col-md-2-4
                offset-md-1-4
                d-flex
                justify-content-center
              "
            >
              <button type="submit" class="btn btn-primary" :disabled="loading">
                <i class="bi bi-file-earmark-arrow-up" v-show="!loading"></i>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-show="loading"
                >
                </span>
                {{ loading ? "Enviando, aguarde..." : "Enviar Documentação" }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </interna>
</template>
<script>
export default {
  metaInfo: {
    title: "Inscrição",
  },

  data: function () {
    return {
      loading: false,
      selo: null,
      categoria: null,
      arquivo: {
        tipos: [".doc", ".docx", ".pdf"],
        limite: 1024 * 500,
      },
      segmentos: [
        "Meios de Hospedagem e Acampamentos",
        "Agências e Receptivos Turísticos",
        "Centro de Eventos, Convenções e Feiras",
        "Restaurantes, Bares e Similares",
        "Locadoras de Veículos",
        "Estabelecimentos com Espaços para Lojistas de Diversas Atividades",
        "Parques Temáticos, Aquáticos e Equipamentos de Lazer",
        "Guias de Turismo",
      ],
      campos: {
        cnpj: null,
        segmento: null,
        selo: null,
        categoria: null,
        aceito: true,
      },
    };
  },

  mounted: function () {
    this.$sdk.get("/selos", { link: this.$route.params.selo }, (res) => {
      this.selo = res.data[0];
      this.campos.selo = this.selo.ID;
    });
    this.$sdk.get(
      "/categorias",
      { link: this.$route.params.categoria },
      (res) => {
        this.categoria = res.data[0];
        this.campos.categoria = this.categoria.ID;
      }
    );
  },

  methods: {

    get_termos(){
        return this.selo.Termos.replaceAll("\n", '<br />');
    },
    submit() {
      var $this = this;
      this.loading = true;

      this.$sdk.post(
        "/inscricao",
        Object.assign(this.campos, { empresa: this.$store.state.empresa.ID }),
        () => {
          this.$swal({
            title: "Tudo certo!",
            text: "Rcebemos a sua documentação. Assim que estiver liberado, seu selo estará disponível para download.",
            type: "success",
            icon: "success",
            confirmButtonText: "Ok, entendi.",
          }).then(function () {
            $this.loading = false;
            $this.$router.push({ name: "dashboard" });
          });
        }
      );
    },

    mandou_arquivo(input, path_var) {
      console.log("[CAMPO]", input.files[0]);

      //verifica tamanho do arquivo
      if (input.files[0].size > this.arquivo.limite) {
        this.$swal({
          title: "Arquivo muito grande",
          text: "Você selecionou um arquivo maior que 500kb. Envie seu arquivo novamente.",
          type: "warning",
          icon: "warning",
          confirmButtonText: "Ok, entendi.",
        }).then(function () {
          input.setAttribute("type", "text");
          input.setAttribute("type", "file");
        });
      }

      var reader = new FileReader();
      reader.readAsDataURL(input.files[0]);
      reader.onload = () => {
        console.log("[CONTEÚDO]", reader.result);
        eval(path_var + " = reader.result;");
      };
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      console.log("[files]", files);

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        console.log("[CONTEÚDO]", reader.result);
      };
    },
  },
};
</script>

<style lang="scss">
#inscricao {
  .form-label {
    font-weight: 600;
  }

  .termo {
    height: 200px;
    overflow-y: scroll;
    padding: 15px;
    border: 1px solid #ced4da;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    border-radius: 5px;
  }
}
</style>